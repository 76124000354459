ul li {
    list-style: none;
}

.dashboard-header {
    display: grid !important;
    grid-gap: 1rem;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.info-section div {
    width: 106px !important;
}
.recharts-surface {
    width: 100%;
}
.app_content_charts {
    margin-top: 30px;
    h1 {
        padding-left: 20px;
    }
}

.title_and_select_df {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 33px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        border-bottom: 1px solid #d9d9d9;
        width: 130px;
    }
}

.bg_fff {
    background: #fff;
    border-radius: 5px;
    box-shadow: -2px 3px #e1e7eb94;
}
.display_flex_space_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    padding: 0;
    div {
        padding-top: 10px;
    }
}
.content_box_dashbord {
    width: 100%;
    // min-height: 580px;
    padding: 10px 20px;
}
.br_btm {
    div > p {
        color: #6a7082;
        font-size: 15px;
    }
    div > ul > li {
        font-size: 13px;
        color: #a1a7b8;
    }
}

.height_list_dashboard {
    div {
        border-bottom: 1px solid #e7ecef;
    }
}

.title_number h1 {
    font-weight: 500;
    font-size: 40px;
    color: #383e4f;
}
.height_list_dashboard {
    min-height: 330px;
}
.btn_dashbord {
    display: flex;
    justify-content: flex-end;
    button {
        width: 175px;
    }
}

.cards-section {
    // margin-top: 20px;
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.inline-feedbacks-cards {
    display: grid;
    grid-gap: 1rem;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

// .recharts-wrapper{
//     position: relative;
//     cursor: default;
//     width: 100%!important;
//     height: 200px;
// }
