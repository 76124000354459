@import "./styles/colors";
@import "./styles/global";
@import "./styles/elements";
@import "./styles/ant-design";

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.input-style {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid gray;
    outline: none !important;
    padding-left: 0px !important;
    &:hover {
        outline: none !important;
        border-color: greenyellow;
    }

    &:active {
        outline: none !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none;
    }
}

.page-style {
    text-align: start;
}

.round-box {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $th_color;
}

textarea {
    resize: none !important;
}

.app-name {
    margin: 0;
    font-weight: bold;
    letter-spacing: 1px;
    color: $title_color;
}

.txt-tb-r {
    text-align: right;
}
.txt-tb-l {
    text-align: left;
}
