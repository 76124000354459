@import "../../../styles/colors";

.list-section {
    display: grid;
    grid-gap: 1rem;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.add-component {
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
        width: 13px;
        height: 13px;
        path {
            fill: #3d3d3c;
        }
    }
}

.gallery-style {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 160px;
    height: 160px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &:hover .gallery-remove {
        opacity: 1;
    }
}

.image-gallery {
    width: 140px !important;
    height: 110px !important;
    object-fit: cover !important;
}

.video-gallery {
    width: 140px !important;
    height: 110px !important;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        width: 30px;
        height: 30px;
        path {
            fill: white;
        }
    }
}

.gallery-description {
    width: 100%;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
}

.gallery-remove {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #ffffff;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.2s;
    cursor: pointer;

    svg {
        path {
            fill: $danger_color_light;
        }
    }

    &:hover {
        background-color: $danger_color_light;
        svg {
            path {
                fill: #ffffff;
            }
        }
    }
}

.gallery-length-block {
    width: 60px;
    margin: auto;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 5px;
    &:hover {
        background-color: $th_color;
    }
}
