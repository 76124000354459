@import "../../../styles/colors.scss";

.page {
    width: 100%;
    height: 100vh;
    display: flex;
}

.image-section {
    width: 50%;
    background-image: url("../../../config/images/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 20px;

    .app-version {
        background-color: rgba(0, 0, 0, 0.598);
        padding: 10px;
        border-radius: 5px;

        span {
            color: white;
            font-size: 18px;
            font-weight: bold;
        }
    }
}

.form-section {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: 60%;
        height: 80%;

        .form-style {
            margin-top: 100px;
            text-align: start;
        }
    }
}

.checkbox-forgotPassword {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        color: $blue !important;
    }
}

.text-in-reset-password {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #6a7082;
}

.reset-password-buttones {
    display: flex;
    justify-content: space-between;
}

.login-error {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    color: $error_color;
}