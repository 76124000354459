@import "../../../styles/colors.scss";

.title-in-drawer h2 {
    font-size: 19px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: $grey;
}

.video_content {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.global_header-title-drawer {
    display: flex;
    justify-content: space-between;
}

.two_button_flex {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}



.app-items-settings {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app_row_col_slider {
    display: flex;
    justify-content: space-between;

    .ant-slider {
        width: 100%;
    }
}

.section-items-settings {
    width: 100%;
    display: grid;
    grid-gap: 3px;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

.item-settings {
    height: 270px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $white !important;
    border-radius: 3px;
    border: 1px solid transparent;
    transition: 0.3s;

    svg {
        margin-left: -12px;
        margin-bottom: 10px;
        transition: 0.3s;
    }

    h2 {
        line-height: 32px;
        color: $title_color;
        transition: 0.3s;
    }

    p {
        font-size: 13px;
        color: $grey;
        text-align: left;
        margin-top: -12px;
        transition: 0.3s;
    }

    &:hover {
        box-shadow: 0px 0px 4px 0px #0000002e;
        cursor: pointer;
        border-color: $blue;

        h2,
        p {
            color: $blue;
        }

        svg {
            path {
                fill: $blue !important;
            }
        }
    }
}

.roles-checkbox-section {
    display: flex;
    flex-direction: column;

    label {
        margin-top: 10px;
        margin-left: 0px !important;
    }
}

.change-password-button {
    color: $blue;
    cursor: pointer;
    transition: 0.2s;
    margin-bottom: 10px;

    svg {
        margin-left: 10px;

        path {
            fill: $blue;
        }
    }

    &:hover {
        color: #0230a5;

        svg {
            path {
                fill: #0230a5;
            }
        }
    }
}

.preference-form {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid #8080800f;
    ;
    padding: 8px;

    p {
        padding-top: 5px;
    }
}

.settings_icon_pt {
    .table-head-component {
        padding: 39px 0px;
    }
}