.page-header {
    padding: 30px;
    background-color: $page_header_color;
    padding: 10px 25px;

    text-align: start;
    min-height: 110px;

    .title {
        font-size: 18px;
        font-weight: 400;
        color: $title_color;
    }

    .content {
        display: flex;
        justify-content: space-between;

        .info-section {
            display: flex;
            grid-gap: 40px;
        }
    }
}

.header-item {
    border-top: 1px solid rgba(161, 167, 184, 0.5);
    width: 160px;

    h3 {
        color: #6a7082;
        margin: 0;
        font-size: 13px;
    }

    p {
        width: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        color: #383e4f;
        margin: 0;
    }
}

.page-blurred {
    filter: blur(20px);
    transition: 0.3s;
}

.page-body {
    padding: 10px 25px;
    text-align: start;
}

.text-ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.table-action-button {
    &:active {
        color: $grey !important;
        border-color: $grey !important;
    }

    &:hover {
        color: $grey !important;
        border-color: $grey !important;
    }

    &:focus {
        color: $grey !important;
        border-color: $grey !important;
    }
}

.confirm-yes-button {
    background-color: $danger_color_light !important;
    border: none !important;

    &:hover {
        background-color: $danger_color !important;
    }
}

.green-yes-button {
    background-color: #2a9d8f !important;
    border: none !important;

    &:hover {
        background-color: #2a9d8f !important;
    }
}

.save-icon {
    svg {
        path {
            fill: #2a9d8f !important;
        }
    }
}

.x-icon {
    svg {
        path {
            fill: $danger_color !important;
        }
    }
}

.ant-table-body .ant-table-content {
    overflow: auto;
}

.ant-table-body::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #f5f5f5;
}

.ant-table-body::-webkit-scrollbar {
    width: 15px;
    height: 7px;
    background-color: #f5f5f5;
}

.ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #e1e7eb;
}

.ant-table-sticky-scroll-bar {
    background-color: #c8ced2 !important;
}

.ant-table-content::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #f5f5f5;
}

.ant-table-content::-webkit-scrollbar {
    width: 15px;
    height: 7px;
    background-color: #f5f5f5;
}

.ant-table-content::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #e1e7eb;
}

.epg-search {
    position: relative;

    .container {
        width: 100%;
        max-height: 200px;
        background-color: white;
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 1;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        transition: all 0.3s;
        overflow: auto;

        .item {
            padding: 10px;
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                background-color: #b3b3b31f;
            }
        }

        .empty {
            padding: 10px;
            text-align: center;
            color: #abb0c0;
            margin: 0;
        }
    }
}

.inputs-grid {
    display: grid;
    grid-gap: 10px;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .ant-form-item {
        margin-bottom: 0px;
    }
}

.exportBtn {
    position: relative;
    background: inherit !important;
    border: none !important;
    font-size: 23px !important;
    color: #a1a7b8 !important;
    margin-top: -21px !important;
}

.exportBtnHid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.read-or-hide {
    color: #a1a7b8 !important;
    cursor: pointer;
    transition: 0.5s ease;

    &:hover {
        color: #1890ff !important;
    }
}

.images-block {
    display: grid;
    grid-gap: 10px;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}