@import "../../../styles/colors.scss";

.messages-section {
    margin-top: 20px;
    height: 87vh;
    background-color: white;
    border: 1px solid $chat_color;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
}

.all-messages {
    // width: 250px;
    border-right: 1px solid $chat_color;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 10px;
        border-bottom: 1px solid $chat_color;
        color: $title_color;
    }

    .no-matched-search {
        height: 100%;
        color: #a7a7a7;
        display: flex;
        justify-content: center;
        font-size: 13px;
        align-items: center;

        div {
            text-align: center;
        }
    }

    section {
        width: 100%;
        height: calc(100% - 75px);
        // height: 438px;

        overflow: auto;

        .message {
            display: flex;
            align-items: center;
            text-align: start;
            padding: 15px;
            grid-gap: 5px;
            border-bottom: 1px solid $chat_color;
            cursor: pointer;

            .round-box {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 18px;
                color: #a1a7b8;
                position: relative;

                .online-box {
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    background-color: gray;
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    border: 2px solid white;
                }
            }

            .user-info {
                width: calc(100% - 45px);

                .message-and-not-read-count {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    position: relative;

                    .not-read-count {
                        width: 25px;
                        height: 23px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $blue;
                        color: white;
                        font-size: 10px;
                        font-weight: bold;
                        border-radius: 50%;
                    }
                }
            }

            h4 {
                margin: 0;
                color: #6a7082;
            }

            p {
                font-size: 13px;
                margin: 0;
                color: #a1a7b8;

                span {
                    font-size: 12px;
                }
            }
        }
    }
}

.chat-section {
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        height: 60px;
        border-bottom: 1px solid #e2ebf0;

        color: $title_color;

        .user-info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
                margin: 0;
                margin-left: 5px;
            }
        }

        .delete-button {
            cursor: pointer;

            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            transition: 0.2s;

            svg {
                path {
                    fill: #ff7575;
                }
            }

            &:hover {
                background-color: #f67576;

                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }

    #chat_list {
        height: calc(100% - 150px);
        overflow: auto;
    }

    .content_chat {
        overflow: auto;
        padding: 0px 30px 10px 30px;
        margin-bottom: 20px;
    }

    .content_chat::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: #f5f5f5;
    }

    .content_chat::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
    }

    .content_chat::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #e1e7eb;
    }

    .pleace-to-write {
        width: 100%;
        padding: 0px 40px;
        border-top: 1px solid #e2ebf0;
        margin-bottom: -65px;
        position: relative;

        textarea {
            border-radius: 4px;
        }

        p {
            font-size: 13px;
            color: #abb0c0;
        }

        button {
            height: 25px !important;
            background: #fff !important;
            border: none !important;
            min-width: 20px !important;
            position: absolute;
            right: 2px;
            z-index: 1;
            top: -52px;
        }
    }
}

.new-message-area {
    width: 100%;
    height: 90px;
    border-top: 1px solid $chat_color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    textarea {
        width: 97%;
        height: 100%;
        border-color: red;
        outline: none;
        padding: 5px;
        border: 1px solid $chat_color;
        border-radius: 10px;
    }
}

.user-online {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: gray;
    border: 2px solid white;
}

.select-conversation-section {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 100px;
        height: 100px;
    }

    h1 {
        font-size: 25px;
        margin: 0;
        color: rgb(80, 81, 93);
    }

    p {
        width: 220px;
        margin: 0;
        color: rgb(167, 167, 167);
    }
}

.message-style {
    width: 100%;
    padding: 5px 10px;
    display: flex;
    text-align: start;

    p {
        margin: 0;
    }

    .admin-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .info-section {
        width: calc(100% - 30px);
    }

    .admin-image-round {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: $th_color;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #a1a7b8;
        font-weight: bold;
    }

    .date-style {
        color: #abb0c0;
        margin-left: 5px;
        font-size: 11px;
    }

    .seen {
        width: 100% !important;
        text-align: end;

        // svg {
        //     path {
        //     }
        // }
    }
}

.quick-messages {
    width: 250px;
    border-left: 1px solid $chat_color;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 10px;
        border-bottom: 1px solid $chat_color;
        color: $title_color;
    }

    .tab-list {
        width: 100%;
        overflow: auto;
        height: calc(100% - 60px) !important;
    }

    .sample-messages-box {
        border: 1px solid #eee;
        border-radius: 8px;
        background: aliceblue;
        margin: 7px 4px;
        transition: 0.2s;
        position: relative;
        padding: 5px 10px;

        display: flex;
        justify-content: space-between;

        cursor: pointer;

        .buttons-section {
            cursor: pointer !important;

            .quick-messages-buttons {
                padding: 5px;
                transition: 0.2s;

                &:hover {
                    background-color: #e2ebf0;
                }
            }
        }
    }

    .empty {
        text-align: center;
    }

    .guest {
        color: rgb(161, 167, 184) !important;
        cursor: no-drop !important;
    }
}

.scroll-box::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #f5f5f5;
}

.scroll-box::-webkit-scrollbar {
    width: 5px;
    height: 7px;
    background-color: #f5f5f5;
}

.scroll-box::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #e1e7eb;
}

.send-button {
    padding: 7px;
    cursor: pointer;

    svg {
        transition: 0.2s;
    }

    &:hover {
        svg {
            path {
                fill: #1d58ee;
            }
        }
    }
}

.notification-new-message {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background-color: #e8f8e6 !important;
    }
}

.product-service {
    margin-top: 5px;
    border: 1px solid grey;
    padding: 5px;
    position: relative;
    min-width: 300px;
    border-radius: 5px;

    .title {
        width: max-content !important;
        top: -12px;
        position: absolute;
        background-color: white;
    }
}

.product-service.pending {
    cursor: pointer;
    transition: 0.2s;
}



.room-section {
    padding: 10px;

}


.room-header {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: .5px solid #e0e0e0;
    background-color: #f5f5f5;
    border-radius: 10px;
    cursor: pointer;
    transition: .1s;

    &:hover {
        background-color: #e0e0e0;
    }
}

.active {
    background-color: #e6f7ff;
}



.room-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #1890ff;
    font-size: 12px;
    color: #fff;

    .active {
        background-color: #52c41a;
    }

}

.room-name {
    display: flex;
    // justify-content: space-between;
    padding: 10px;

    border-radius: 12px;
    cursor: pointer;

    // &:hover {
    //     background-color: #e0e0e0;
    // }
}

.conversation-list {
    padding-left: 20px;
    margin-top: 10px;
}

.message {

    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 10px;

    cursor: pointer;
}

.round-box {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #e0e0e0;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    margin-right: 10px;
}

.user-info {
    display: inline-block;
    vertical-align: top;
}

.message-and-not-read-count {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.not-read-count {
    background-color: red !important;
    color: white;
    padding: 2px 6px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
}