@import "../../../styles/colors.scss";

.tv-app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tv-style {
    width: 90%;
    max-width: 950px;
    // min-height: 500px;
    display: flex;
    flex-direction: column;
    position: relative;

    .disabled {
        opacity: 0.2;
    }

    .body {
        width: 100%;
        height: 36%;
        display: flex;
        align-items: center;
        padding: 20px 24px;

        .welcome-message {
            width: 290px;
            color: white;
            font-size: 25px;
            margin: 0;
        }
    }

    .body-content {
        color: white;
        font-size: 33px;
        width: 230px;
        margin-left: 54px;
        font-weight: 600;
        letter-spacing: 1.1px;
    }

    .footer {
        height: 140px;
        overflow-x: auto;
        padding-top: 20px;

        // height: 40%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // .list {
        //     width: 97%;
        //     padding-top: 20px;
        //     display: flex;
        //     padding-bottom: 0px;
        // }
    }

    .footer::-webkit-scrollbar {
        background-color: white;
        height: 6px;
    }

    .footer::-webkit-scrollbar-thumb {
        background: #7f80838a;
    }
}

.tv-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    object-fit: cover;
    padding: 10px;
}

.tv-body {
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #00000075;
    border-radius: 10px;
    border: 10px solid black;
}

.header-content {
    display: grid;
    grid-gap: 1rem;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    justify-content: space-between;
    padding: 20px 24px;
    position: relative;
    color: white;
    width: 100%;
}
.items-wrap-return {
    position: relative;
    margin-top: 30px;
    transition: 0.2s;
    &:hover {
        .editItemHover {
            opacity: 1;
        }
    }
}

.tv-app-menu-item {
    text-align: center;
    cursor: pointer;

    .container {
        width: 118px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;

        .image {
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: contain;
        }

        p {
            margin: 0;
        }
    }
}

.weather-block {
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    p {
        margin: 0;
    }

    .o {
        position: absolute;
        font-size: 15px;
    }

    .c {
        margin-left: 10px;
        margin-right: 5px;
    }
}

.date-block {
    display: flex;
    justify-content: flex-end;
}

.date-edit-config-button {
    width: 100% !important;
    padding: 0 !important;
}

.add-config-button {
    width: 100%;
    height: 80px;
    border: 1px dashed white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    p {
        color: white;
        margin: 0;
    }

    svg {
        width: 40px;
        margin-bottom: 5px;

        path {
            fill: white;
        }
    }
}

.edit-config-button {
    width: max-content;
    min-height: 80px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
    position: relative;
    border: 1px dashed transparent;
    border-radius: 10px;

    .edit-button {
        font-size: 8px;
        opacity: 0;
        transition: 0.2s;
        margin-left: 5px;
        position: absolute;
        top: -10px;
        left: 5px;
    }

    &:hover {
        border-color: white;
        .edit-button {
            opacity: 1;
        }
    }
}

.tv-config-menu-item {
    width: 120px;
    height: 100px;
    // margin-bottom: 10px;
    position: sticky;
}

.tv-page {
    margin-top: 20px;
    width: 100%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../../config/images/tv-page.png);
}

.date-values {
    display: flex;
    font-size: 10px;
}

.edit-config-button {
    padding: 0;
}
