// a
// b
$blue: #2f6bff;

$body_color: #ebeff1;
// c
$chat_color: rgb(234, 232, 234);
// d
$danger_color: #ad3d3f;
$danger_color_light: #bc4749;

$button_color: #a1a7b8;
$button_active_color: #2f6bff;

// e
$error_color: #bc4749;
// f
// g
$grey: #a1a7b8;
$greyIcon: #6a7082;

$green: #2a9d8f;

// h
// i
// j
// k
// l
$label_color: #abb0c0;

// m
// n
// o
// p
$page_header_color: #e1e7eb;
// q
// r
// s
// t
$title_color: #383e4f;
$th_color: #e2ebf0;
// u
// v
// w
$white: #fff;
// x
// y

$yellow_color: #d46b08;
$yellow_color_light: #fa8c16;
// z