@import "../../../../../styles//colors.scss";

.movie-form-section {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.tmdb-search-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tmdb-search {
    // width: 500px;
    width: 100%;
    position: relative;

    .container {
        width: 100%;
        max-height: 700px;
        background-color: white;
        position: absolute;
        top: 70px;
        left: 0;
        z-index: 1;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        transition: all 0.3s;
        overflow: auto;
        padding: 10px;

        .empty {
            padding: 10px;
            text-align: center;
            color: #abb0c0;
            margin: 0;
        }
    }

    .loading-and-empty {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.tmbd-movie {
    margin-bottom: 10px;
    display: flex;
    // border-bottom: 1px solid $grey;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background-color: green;
        background-color: $th_color;
    }

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 2px;
    }

    .info {
        width: 100%;
        margin-left: 10px;
        border-bottom: 1px solid $grey;

        p {
            margin-bottom: 5px;
        }

        .name {
            font-size: 16px;
            font-weight: bold;
            color: $title_color;
        }

        .type {
            color: $grey;
        }

        .date {
            color: $grey;
        }
    }
}

.tmdb-image-style {
    object-fit: cover;

    .ant-image {
        width: 100%;
    }

    img {
        object-fit: cover;
        height: 170px;
    }
    margin-bottom: 10px;
}

.row-block {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.type-price {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 3fr 1fr;
}

.seasons-section {
    margin-bottom: 20px;

    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.active-season-row {
    background-color: #89c2d9;
    cursor: pointer;
}

.season-row {
    cursor: pointer;
}

.season-episode-tables-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.movie-info {
    text-align: start;
    display: flex;
    grid-gap: 10px;

    img {
        width: 60px;
        height: 70px;
        object-fit: cover;
        border-radius: 5px;
    }

    h1 {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }

    span {
        font-size: 11px;
        color: $grey;

        b {
            font-weight: bold;
        }
    }
}
