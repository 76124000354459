.monitoring-header {
    border-bottom: 1px solid #e1e7eb;
    margin-bottom: 20px;
}

.monitoring-box {
    display: flex;
    align-items: center;
    text-align: start;
    width: 65%;
    min-height: 80px;
    gap: 10px;
}

.card-body {
    display: grid;
    grid-gap: 10px;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.monitoring-item {
    .icon-name {
        display: flex;
        align-items: center;

        p {
            margin: 0;
        }
    }
    svg {
        margin-right: 5px;
        path {
            fill: #494949;
        }
    }

    .monitoring-info {
        b {
            margin-right: 5px;
        }
    }
}

.empty-block {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-matched-search {
    height: 50vh;
    width: 100%;
    color: #a7a7a7;
    display: flex;
    justify-content: center;
    font-size: 13px;
    align-items: center;
    div {
        text-align: center;
    }
}
