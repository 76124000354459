@import "../../../styles/colors.scss";

.you-can-use-text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
}

.filters-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    .buttons-section {
        display: flex;
        align-items: center;
        grid-gap: 10px;
    }
}

.filters-button-item {
    padding: 5px 10px;
    font-size: 16px;

    &:hover {
        cursor: pointer;
    }
}

.add-tv-section {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.sub-table {
    margin-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: #eff2f4;

    td,
    th {
        border-bottom: 1px solid #ddd;
        padding: 20px;
    }

    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
    }

    .buttons-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.view-settings-checkboxes {
    display: flex;
    flex-direction: column;

    label {
        margin: 0 !important;
        margin-bottom: 5px !important;
    }
}

.two_sections_content {
    gap: 15px;
    margin: 40px 0px;
    display: grid;
    grid-template-columns: 30% 70%;
}

.activeItem {
    cursor: pointer;
}

.content_box_host {
    padding: 10px 20px;
}

.height_list_orders {
    min-height: 330px;
    min-width: 207px;

    div {
        border-bottom: 1px solid #e7ecef;
    }
}

.childTitle {
    padding-top: 5px;
    font-size: 17px;
}

.mt_12 {
    margin-top: 12px;
}

.completedBtn {
    button {
        min-width: 85px !important;
    }
}

.single-location-header {
    padding: 11px 14px;
    border-bottom: 2px solid #e7ecef;
    transition: all 1s ease;
    display: flex;
    align-items: baseline;

    .onBack {
        cursor: pointer;
        color: #383e4f;
        font-size: 27px;
        width: max-content;

        &:hover {
            color: #1890ff !important;
        }

        span svg {
            font-size: 23px;
        }
    }

    .title-single-location-name {
        text-align: center;
        width: 53%;
    }
}

.host_keys {
    div {
        margin-top: 33px;
        color: #a1a7b8;

        span {
            color: #383e4f;
        }
    }
}

.df_attach_title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 0px;
    margin-bottom: 10px;
}

.df_check_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-component {
        min-width: 133px !important;
    }
}

.df {
    display: flex;
    gap: 20px;
}

.conteiner-head-table {
    display: flex;
    justify-content: space-between;
}

.completed {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7ecef;
    align-items: center;

    .title {
        color: #a1a7b8;
    }
}

.activeRow {
    .ant-table-row {
        transition: 0.5s ease;

        &:hover {
            color: #1890ff !important;
        }
    }
}

.ordersScroll {
    .ant-table-body {
        max-height: 250px;
    }

    .ant-table-body::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: #f5f5f5;
    }

    .ant-table-body::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: #f5f5f5;
    }

    .ant-table-body::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #e1e7eb;
    }
}

.currency-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.expired-row {
    background-color: #ffccd5;
}

.last-day-row {
    background-color: #d8f3dc;
}