.welcome-page {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #e5e5e5;

    .container {
        width: 100%;
        height: 100%;
        background-color: #0000006e;
        z-index: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;

        .progress-block {
            width: 100%;
            height: 92%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        footer {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}

.welcome-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(7px);
}
