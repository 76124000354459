.services-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
   
}
.services-header .title-and-info-section {
    width: 70%;
    height: 100%;
}