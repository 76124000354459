@import "../../styles/colors";

#sidebar {
    background-color: white;
}

#sidebar::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #e1e7eb;
}

#sidebar::-webkit-scrollbar {
    width: 5px;
    background-color: #e1e7eb;
}

#sidebar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #abb0c0db;
}

.sidebar-logo-section {
    header {
        height: 120px;

        .logo-block {
            width: 100%;
            height: 90px;
            display: flex;
            justify-content: center;
        }

        .version-block {
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 10px;
        }

        span {
            font-size: 12px;
            color: grey;
        }
    }
}

.sidebar-menu {
    overflow: auto;

    ul {
        font-size: 16px;
    }

    svg {
        margin-right: 10px;
        width: 20px;
        height: 20px;
    }

    .ant-menu {
        color: #abb0c0 !important;
    }

    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
        color: #abb0c0;
    }

    .ant-menu-item {
        color: #abb0c0;

        &:hover {
            color: #2f6bff !important;
        }
    }

    .ant-menu-submenu .ant-menu-submenu-inline {
        &:hover {

            .ant-menu-submenu-expand-icon,
            .ant-menu-submenu-arrow {
                color: #2f6bff !important;
            }
        }
    }

    .ant-menu-submenu-title {
        &:hover {
            color: #2f6bff !important;
        }
    }

    .ant-menu-submenu-selected {
        color: #2f6bff !important;
    }

    .ant-menu-item-selected {
        color: #2f6bff !important;
    }
}

.pages-content {
    background-color: #eff2f4 !important;
    min-height: 360px;
    margin-top: 64px;
}

.root-header {
    background-color: white !important;
    background-color: #191919 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: fixed;
    top: 0;
    z-index: 1000;

    .trigger {
        margin-left: 20px;
        color: white;
    }
}

#profile-block {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 20px;
    transition: 0.5s;
    line-height: 20px !important;

    &:hover {
        background-color: #f0f2f51f;
    }

    p {
        margin: 0;
        font-size: 17px;
        color: white;
        letter-spacing: 0.5px;
    }

    img {
        width: 40px;
        border-radius: 50%;
    }

    .profile-online {
        .ant-badge-status-text {
            color: white;
            font-size: 12px;
        }
    }
}

.offline-textarea {
    text-align: center;

    &::placeholder {
        color: #ff4d4f;
    }
}

#uploading_item_id {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 20px;
    transition: 0.5s;

    &:hover {
        background-color: #f0f2f51f;
    }

    p {
        margin: 0;
        font-size: 17px;
        color: white;
        letter-spacing: 0.5px;
    }
}

.icon-uploader-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploading-video {
    display: flex;

    img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        border-radius: 5px;
        margin-right: 10px;
    }

    p {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
    }

    .type {
        font-size: 13px;
        color: gray;
    }
}

.uploading-videos-component {
    max-height: 80vh;
    overflow-y: auto;
}

.order-notification-style {
    display: flex;

    img {
        width: 50px;
        margin-right: 10px;
    }
}

.df-list-lng {
    display: flex;
    gap: 14px;
    align-items: center;
}

.wrap-leng-menu {
    position: absolute;
    top: -7px;
    left: -23px;

    li {
        margin-bottom: 5px !important;

        &:hover {
            background-color: #f5f5f5;
            color: inherit !important;
        }
    }
}

.profile-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.root-header-logo {
    margin-left: 20px;
}