@import "../../../styles/colors.scss";

.grid-container {
    display: inline-grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 7px;
}

.column {
    flex: 100%;

    .ant-image {
        width: 100%;
    }
}

.audio-component {
    width: 100%;
    height: 170px;
    background-color: #e1e7eb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
}

.video-component {
    width: 100%;
    cursor: pointer;
    transition: 0.2s;
    background-color: #e1e7eb;
    margin-bottom: 10px;

    p {
        padding: 5px;
    }

    div {
        height: 170px;
        height: 220px;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;

        color: white;
        font-size: 30px;
        transition: 0.2s;
    }

    &:hover {
        color: $button_active_color;

        div {
            color: $button_active_color;
        }
    }
}

.media-component {
    position: relative;
    padding: 7px 2px;

    &:hover {
        .buttons {
            opacity: 1;
        }
    }  
}

.buttons {
    opacity: 0;
    top: 18px;
    right: 12px;
    position: absolute;
    z-index: 1;
}

.media-table-image {
    width: 100px !important;
    height: 100px !important;
    object-fit: cover !important;
}

.media-table-video {
    div {
        width: 100px;
        height: 100px;
    }
}

.media-table-audio {
    width: 100px;
    height: 100px;
    padding: 0;
}
