@import "./colors";

.title-style {
    font-size: 32px;
    font-weight: 400;
    color: $title_color;
}

.button-component {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 4px !important;
    min-width: 160px !important;

    height: 40px !important;

    background-color: $button_color !important;
    color: white !important;

    span {
        // margin-right: 15px;
        font-size: 16px;
        font-weight: 600;
    }

    &:hover {
        background-color: $button_active_color !important;
    }

    &:focus {
        outline: 0 !important;
        border-color: transparent !important;
    }

    &.danger {
        background-color: $danger_color_light !important;
        border: none !important;

        &:hover {
            background-color: inherit;
        }
    }

    &.danger_disabled {
        background-color: $grey !important;
        outline: none !important;

        &:hover {
            background-color: inherit;
        }
    }
}

// .ant-btn ant-btn-danger {
//     background-color: $danger_color !important;
// }

.app-button {
    background-color: $grey !important;
    color: white !important;

    &:hover {
        background-color: $blue !important;
    }
}

.blue-button {
    color: white !important;
    background-color: $blue !important;
}

.grey-button {
    color: white !important;
    background-color: $grey !important;
}

.transparent-button {
    background: transparent !important;
    border: none !important;
}

.chooser-is-active {
    .chooser {
        border-color: #619b8a;
        color: #619b8a;
        background-color: #e6e9f1;

        svg {
            path {
                fill: #619b8a;
            }
        }
    }
}

.image-format {
    transition: 0.3s;
}

.image-error-types {
    color: #ff4d4f;

    font-size: 20px;
}

.table-head-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;

    .buttons-section {
        display: flex;
        align-items: center;
        grid-gap: 10px;
    }
}

.icon-button {
    padding: 5px 10px;
    font-size: 16px;

    &:hover {
        cursor: pointer;
    }
}

.icon-button.active {
    svg {
        path {
            fill: $button_active_color;
        }
    }
}

.table-empty {
    padding: 32px 0;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.25);

    .ant-empty-image {
        height: 40px;
    }
}

.label-component {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;

    label {
        color: #abb0c0;
        font-size: 14px;
    }
}

.replice-video {
    color: #2f6bff;
    margin: 10px 0px 0px 0px;
    width: max-content;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        color: #0230a5;
    }
}

.accept-reject-btn {
    // width: 50px;
    padding: 3px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 5px;
    transition: 0.2s;
    border-radius: 5px;
    cursor: pointer;

    // &:hover {
    //     background-color: #1e1e1e14;
    // }
}

.ant-image {
    .tableImage {
        width: 60px !important;
        height: 60px !important;
        object-fit: cover;
        background: #eff2f4;
    }
}

.downloaded-file {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f7f7f7;
}

.file-chooser-component {
    width: 100%;

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 3px;
        background-color: #f5f5f5;
    }

    .chooser {
        width: 100%;
        height: 140px;
        border-radius: 3px;
        border: 1px solid #d9d9d9;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        color: #a8a8a8;
        padding: 20px;
        cursor: pointer;
        position: relative;

        .input-chooser {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        .text-blcok {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .replice {
        color: #2f6bff;
        margin: 10px 0px 0px 0px;
        width: max-content;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            color: #0230a5;
        }
    }
}

.chooser-container {
    width: 100%;
    margin-bottom: 10px;
    position: relative;

    .ant-image {
        width: 100% !important;
        background-color: #a1a7b8;
    }

    .choosed-image {
        height: 190px;
        object-fit: cover;
    }

    .choosed-video {
        width: 100%;
        min-height: 190px;

        object-fit: cover;
    }

    .input-chooser {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    .replice {
        color: #2f6bff;
        width: max-content;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            color: #0230a5;
        }
    }
}

.file-chooser {
    width: 100%;
    height: 190px;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #a8a8a8;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        color: $blue;
        border-color: $blue;
    }
}

.element-search {
    position: relative;

    .container {
        width: 100%;
        max-height: 200px;
        background-color: white;
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 1;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        transition: all 0.3s;
        overflow: auto;

        .item {
            padding: 10px;
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                background-color: #b3b3b31f;
            }
        }

        .empty {
            padding: 10px;
            text-align: center;
            color: #abb0c0;
            margin: 0;
        }
    }
}