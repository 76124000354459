@import "../../../styles/colors.scss";

.app_packeges_flex {
    display: flex;
    justify-content: space-around;
}
.childe_app_packeges_flex {
    width: 47%;
}

.switch-block {
    display: flex;
    grid-gap: 1rem;

    p {
        width: 200px;
    }
}

.packages-sections-setyle {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 20px;

    label {
        margin: 0px !important;
        margin-bottom: 10px !important;
    }
}

.gui-list {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    margin-bottom: 20px;
}

.gui-style {
    width: 160px;
    height: 190px;
    cursor: pointer;

    p {
        margin-bottom: 5px;
    }

    img {
        width: 160px;
        height: 160px;
        object-fit: cover;
        border: 1px solid transparent;
        border-radius: 3px;
    }
}

.gui-style.active {
    p {
        color: $blue;
    }

    img {
        border-color: $blue;
    }
}

.swetch-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 200px;
    margin-bottom: 10px;
    .ant-form-item {
        margin: 0;
    }
}
