.ant-form-item-label {
    label {
        color: $label_color !important;
    }
}

.ant-layout-sider-trigger {
    background-color: transparent !important;

    span {
        color: #002140;
    }
}

.ant-table-cell {
    p {
        margin: 0;
    }
}