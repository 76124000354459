.language-icon-name {
    display: flex;

    .image {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        padding-left: 10px;
    }
}

.switch-block {
    width: max-content;
    margin: auto;
}

.activeRow .ant-table-row:hover {
    color: #1890ff !important;
}
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}
.editInput {
    border: 1px solid transparent;
    transition: all 1s;
    cursor: pointer;
    padding: 7px;
    &:hover {
        border: 1px solid #d9d9d9;
    }
}

.new-word-section {
    width: 100%;
    display: flex;
    gap: 10px;
}
